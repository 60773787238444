const { getInsuranceData } = require("./getInsuranceData")
const { getInvoiceData } = require("./getInvoiceData")

exports.getExpenseData = (
  registrant,
  addons,
  tour,
  extensions,
  tourCosts,
  extensionsCosts,
  insurance
) => {
  let extensionCosts =
    registrant.extension_id &&
    extensionsCosts &&
    extensionsCosts.find(x => x.id == registrant.extension_id)

  let landCost = 0
  if (!registrant.inactive) {
    landCost = tourCosts.land
  }

  let airCost = 0
  if (!registrant.inactive) {
    if (tourCosts.local_air) {
      airCost += tourCosts.local_air
    }
    if (registrant.airtravel_yes) {
      if (extensionCosts && extensionCosts.group_air) {
        airCost += extensionCosts.group_air
      } else {
        airCost += tourCosts.group_air
      }
    }
  }

  let extensionCost = 0
  if (registrant.extension_id && !registrant.inactive) {
    if (extensionCosts) {
      extensionCost += extensionCosts.land
      extensionCost += extensionCosts.local_air
    }
  }

  let insuranceCost = 0
  if (registrant.insurance_yes && !registrant.inactive) {
    const invoiceData = getInvoiceData(
      registrant,
      tour,
      insurance,
      extensions,
      addons
    )
    const insuranceData = getInsuranceData(
      registrant,
      invoiceData.insuredSubtotal,
      insurance
    )
    insuranceCost = insuranceData.cost
  }

  let tourGratuityCost = 0
  if (tourCosts.gratuity && !registrant.inactive) {
    tourGratuityCost = tourCosts.gratuity
  }

  let extensionGratuityCost = 0
  if (registrant.extension_id && !registrant.inactive) {
    if (extensionCosts) {
      extensionGratuityCost = extensionCosts.gratuity
    }
  }

  let gratuityCost = tourGratuityCost + extensionGratuityCost

  let tourSupplementCost = 0
  if (registrant.supplement_yes && !registrant.inactive) {
    tourSupplementCost = tourCosts.supplement
  }

  let extensionSupplementCost = 0
  if (registrant.supplement_yes && !registrant.inactive) {
    if (extensionCosts) {
      extensionSupplementCost = extensionCosts.supplement
    }
  }

  let supplementCost = tourSupplementCost + extensionSupplementCost

  let addonsCost = 0
  if (addons && !registrant.inactive) {
    addons.forEach(addon => {
      if (addon.registrant_id == registrant.id) {
        addonsCost += addon.cost
      }
    })
  }

  let headsetCost = 0
  if (!registrant.inactive && tourCosts.headset) {
    headsetCost = tourCosts.headset
  }

  let commissionCost = 0
  if (!registrant.inactive && tourCosts.commission) {
    commissionCost = tourCosts.commission
  }

  let bottledWaterCost = 0
  if (!registrant.inactive && tourCosts.bottled_water) {
    bottledWaterCost = tourCosts.bottled_water
  }

  let cruisePortTaxCost = 0
  if (!registrant.inactive && tourCosts.cruise_port_tax) {
    cruisePortTaxCost = tourCosts.cruise_port_tax
  }

  let cruiseFuelSupplementCost = 0
  if (!registrant.inactive && tourCosts.cruise_fuel_supplement) {
    cruiseFuelSupplementCost = tourCosts.cruise_fuel_supplement
  }

  let tourLeaderBusinessClassUpgradeCost = 0
  let groundTransferCost = 0

  let totalCost =
    airCost +
    landCost +
    extensionCost +
    insuranceCost +
    gratuityCost +
    supplementCost +
    addonsCost +
    headsetCost +
    commissionCost +
    bottledWaterCost +
    cruisePortTaxCost +
    cruiseFuelSupplementCost +
    tourLeaderBusinessClassUpgradeCost +
    groundTransferCost

  return {
    airCost,
    landCost,
    extensionCost,
    insuranceCost,
    gratuityCost,
    tourGratuityCost,
    extensionGratuityCost,
    supplementCost,
    tourSupplementCost,
    extensionSupplementCost,
    addonsCost,
    headsetCost,
    commissionCost,
    bottledWaterCost,
    cruisePortTaxCost,
    cruiseFuelSupplementCost,
    tourLeaderBusinessClassUpgradeCost,
    groundTransferCost,
    totalCost
  }
}
